<template>
  <div class="h-100 p-4 d-flex flex-column">
    <div class="flex-grow-1">
      <dynamic-data-renderer
        :payload="participant.participant_details"
      ></dynamic-data-renderer>
    </div>
  </div>
</template>
<script>
import dynamicDataRenderer from "@/shared/components/dynamic-data-renderer.vue";
export default {
  components: { dynamicDataRenderer },
  computed: {
    participant() {
      return this.$store.state.volunteer.currentParticipant;
    },
  },
  methods: {},
};
</script>

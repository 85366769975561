export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const VOLUNTEER_KEY = "volunteer";
export const TENANT_KEY = "tenant";
export const BASE_API_URL_KEY = "baseUrl";
export const SEARCH_BY_TEXT_KEY = "searchBy";

export const UserTypes = {
  participant: "participant",
  volunteer: "volunteer",
};

<template>
  <div class="h-100 d-flex flex-column justify-between">
    <v-btn
      variant="text"
      class="position-absolute top-0 start-0 m-2"
      @click="goBack"
      :icon="true"
    >
      <v-icon size="x-large" color="white">mdi-keyboard-backspace</v-icon>
    </v-btn>
    <div class="flex-grow-1">
      <div
        class="w-100 text-center bg-primary py-3"
        :class="{ 'bg-success': order.checked_in }"
      >
        <div
          v-if="order.checked_in"
          class="position-absolute left-0 fw-bold check-in-label end-0 me-5"
        >
          <v-icon color="white" class="fs-xxl"> mdi-check-circle </v-icon>
        </div>
        <div class="fs-1 fw-bold">
          {{ order.last_name }}
        </div>
        <div class="fs-5">
          {{ order.first_name }}
        </div>
      </div>
      <div class="mt-10 px-5">
        <div>
          <div class="text-uppercase text-caption">Product</div>
          <div class="fw-bold mt-2">{{ order.name }}</div>
        </div>
        <div class="mt-5">
          <div class="text-uppercase text-caption">Quantity</div>
          <div class="fw-bold mt-2 fs-1">{{ order.quantity }}</div>
        </div>
      </div>
    </div>
    <check-in :entity="order" :type="'single-order'" class="pb-5"></check-in>
  </div>
</template>
  <script>
import CheckIn from "@/modules/Volunteer/shared/helpers/components/CheckIn.vue";
export default {
  components: {
    CheckIn,
  },
  computed: {
    order() {
      return this.$store.state.volunteer.currentOrder;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.fs-xxl {
  font-size: 2.5em;
}
</style>
<template>
  <div class="h-100 d-flex align-items-center justify-content-center mt-n10">
    <div class="text-center" v-if="!showQrCodeReader">
      <v-icon
        class="mt-5 clubrac_pulsate"
        color="blue"
        size="100"
        @click="showQrCodeReader = true"
        >mdi-qrcode-scan</v-icon
      >
      <div class="mt-10 text-white">Tap to scan</div>
    </div>
    <div v-if="showQrCodeReader" class="w-100px h-100px">
      <qr-code-reader @decode="getQrEntity"></qr-code-reader>
      <v-row class="mt-10">
        <v-btn block color="white" text @click="showQrCodeReader = false">
          Close
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
  <script>
import QrCodeReader from "@/shared/components/qr-code-reader.vue";
export default {
  components: { QrCodeReader },
  data: () => {
    return {
      showQrCodeReader: false,
      loading: false,
    };
  },
  methods: {
    async getQrEntity(entityId) {
      this.loading = true;
      let response = await this.$store.dispatch("volunteer/getQrEntity", {
        entityId,
      });
      this.loading = false;
      if (response.success) {
        this.$router.push({
          name: response.redirect,
          params: { id: entityId },
        });
      } else {
        this.showQrCodeReader = false;
        this.$toast.warning("Failed to read the QR code. No Entry found.");
      }
    },
  },
};
</script>
  
import { BASE_API_URL_KEY, TENANT_KEY } from "@/models/storage";
import * as types from "./mutation-types";
import api from "@/http/api";

const apiDomain = process.env.VUE_APP_API_DOMAIN;

export default {
  /**
   * Login action
   */
  async loadTenant({ commit }) {
    let tenant = JSON.parse(localStorage.getItem(TENANT_KEY));
    if (!tenant) {
      const response = await api.get(
        `https://${apiDomain}/api/v4/pwa_configurations`
      );
      if (response.data.status == "error") {
        commit(types.TENANT_LOAD_FAILURE);
      } else {
        let tenant = response.data;
        const baseUrl = `https://${tenant.base_url}/api/`;
        localStorage.setItem(TENANT_KEY, JSON.stringify(tenant));
        localStorage.setItem(BASE_API_URL_KEY, baseUrl);
        commit(types.TENANT_LOAD_SUCCESS, tenant);
        window.location.reload();
        return;
      }
    }
    commit(types.TENANT_LOAD_SUCCESS, tenant);
    return tenant;
  },

  /**
   * Check if user is authenticated / stored in local storage
   */
  checkTenant({ commit }) {
    const tenant = localStorage.getItem(TENANT_KEY);

    if (tenant) {
      commit(types.TENANT_LOAD_SUCCESS, { tenant });
    } else {
      commit(types.TENANT_LOAD_FAILURE);
    }
  },
};

<template>
  <div>
    <div
      class="d-flex justify-content-between grey--text mb-5"
      v-for="(entry, index) in payload"
      :key="index"
    >
      <div class="fw-bold">{{ entry.label }}</div>
      <div>{{ entry.value }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["payload"],
};
</script>

import * as types from "./mutation-types";
import api from "@/http/api";

export default {
  /**
   * load events action
   */
  async loadEvents({ commit }, organiserId) {
    const response = await api.get(`organisers/${organiserId}/events`);
    if (response.data.status == "error") {
      commit(types.EVENTS_LOAD_FAILURE);
    } else {
      commit(types.EVENTS_LOAD_SUCCESS, response.data);
    }
  },

  /**
   *
   */
  async loadViewEvent({ commit }, eventId) {
    const response = await api.get(`events/${eventId}`);
    if (response.data.status == "error") {
      commit(types.EVENTS_VIEW_FAILURE);
    } else {
      commit(types.EVENTS_VIEW_SUCCESS, response.data);
    }
  },
};

import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});
Vue.use(VuetifyToast);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#67c7e9",
        secondary: "#6d7b82",
        accent: "#ef483e",
        error: "#b71c1c",
      },
    },
  },
});

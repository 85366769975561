import * as types from "./mutation-types";

export default {
  [types.BOOKINGS_LOAD_SUCCESS](state, bookings) {
    state.bookings = bookings;
  },

  [types.BOOKINGS_LOAD_FAILURE](state) {
    state.bookings = [];
  },
};

<template>
  <div
    class="
      volunteer__intro
      d-flex
      h-100
      w-100
      justify-center
      align-items-center
    "
  >
    <div class="text-center">
      <h2 class="fs-1 mb-10" v-if="tenant">{{ tenant.organisation.name }}</h2>
      <div>
        <h5 class="fs-6 mb-10">Welcome to the Volunteer App</h5>
        <div class="fs-6 text-muted">Tap to get started</div>
        <router-link :to="{ name: 'volunteer:get-started' }">
          <v-icon class="mt-5 clubrac_pulsate" color="blue" size="60"
            >mdi-qrcode-scan</v-icon
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tenant() {
      return this.$store.state.tenant.tenant;
    },
  },
};
</script>

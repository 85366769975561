import axios from "axios";
import { BASE_API_URL_KEY, TOKEN_KEY, VOLUNTEER_KEY } from "../models/storage";
const api = axios.create({
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
    domain: window.location.host,
  },
});
api.defaults.baseURL = localStorage.getItem(BASE_API_URL_KEY) + "v4";
api.defaults.timeout = 5000;
api.interceptors.response.use(
  (response) => {
    if (response.data.code == 401) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(VOLUNTEER_KEY);
      window.location.reload();
    }
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers["token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

export const loadAndGetEntity = async (store, router, entityId) => {
  let response = await store.dispatch("volunteer/getQrEntity", {
    entityId,
  });
  if (response.success) {
    router.push({
      name: response.redirect,
      params: { id: entityId },
    });
  }
};

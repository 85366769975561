<template>
  <div>
    <v-app-bar-nav-icon
      v-if="volunteer.scanning_group"
      @click.stop="drawer = !drawer"
      color="white"
      class="position-absolute z-1 mt-2 ms-2"
    ></v-app-bar-nav-icon>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="z-2"
      dark
      v-if="volunteer.scanning_group"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-5 text-primary">
            Eventrac checkin</v-list-item-title
          >
          <v-list-item-subtitle>
            <div class="fw-bold text-white">
              {{ volunteer.user.first_name }}
            </div>
            {{ volunteer.scanning_group.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div class="h-75 d-flex flex-column">
        <v-list dense nav class="flex-grow-1">
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-list-item link @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="text-muted text-center fs-6">v1.0.0</div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { TOKEN_KEY, USER_KEY, VOLUNTEER_KEY } from "@/models/storage";
export default {
  data: () => ({
    drawer: false,
    items: [],
  }),
  computed: {
    volunteer() {
      return this.$store.state.volunteer;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(VOLUNTEER_KEY);
      this.$router.push({ name: "volunteer:home" });
      this.$toast.success("Logged out.");
    },
  },
};
</script>
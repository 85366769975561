<template>
  <div class="h-100 p-4">
    <div v-if="orders.length == 0" class="text-muted text-center fs-5">
      No orders found
    </div>
    <div
      class="d-flex px-2 py-3 bg-black rounded-2"
      v-for="(order, index) in orders"
      :key="index"
    >
      <div class="flex-grow-1">
        <div class="fs-6 me-4">{{ order.product }}</div>
        <div class="grey--text fs-6">Quantity : {{ order.quantity }}</div>
      </div>
      <div>
        <check-in :entity="order" :type="'order'" :size="'sm'"></check-in>
      </div>
    </div>
  </div>
</template>
<script>
import CheckIn from "@/modules/Volunteer/shared/helpers/components/CheckIn.vue";
export default {
  components: { CheckIn },
  computed: {
    orders() {
      return this.participant.orders;
    },
    participant() {
      return this.$store.state.volunteer.currentParticipant;
    },
  },
  methods: {
    async toggleCheckIn({ value }, qrCode) {
      await this.$store.dispatch("volunteer/checkInOrCheckOutQrCode", {
        checkIn: value,
        qrCode,
        type: "order",
      });
    },
  },
};
</script>
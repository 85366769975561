const AuthLayout = () => import("./AuthLayout.vue");
const Login = () => import("./views/Login.vue");

const moduleRoute = {
  path: "/auth",
  component: AuthLayout,
  children: [
    {
      path: "login",
      name: "login",
      component: Login,
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};

<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <v-card-title class="p-2 pl-5"
          >{{ entity.first_name }} {{ entity.last_name }}</v-card-title
        >
        <v-card-subtitle class="p-2 pl-5">{{ entity.name }}</v-card-subtitle>
      </div>

      <v-icon
        :color="entity.checked_in ? 'success' : 'default'"
        class="me-5 fs-1"
      >
        mdi-check-circle
      </v-icon>
    </div>
    <hr class="mt-n1" />
    <v-card-subtitle class="mt-n1 pt-0 pl-5">
      <div><span class="fw-bold">Quantity : </span> {{ entity.quantity }}</div>
    </v-card-subtitle>
  </div>
</template>
<script>
export default {
  props: ["entity"],
};
</script>
import * as types from "./mutation-types";

export default {
  [types.AUTH_SUCCESS](state, { token, user }) {
    state.token = token;
    state.user = user;
    state.loggedIn = true;
  },

  [types.AUTH_FAILURE](state) {
    state.token = null;
    state.user = null;
    state.loggedIn = false;
  },
};

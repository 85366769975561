import * as types from "./mutation-types";

export default {
  [types.VOLUNTEER_TOKEN_SUCCESS](state, { scanning_group, user }) {
    state.scanning_group = scanning_group;
    state.user = user;
  },

  [types.VOLUNTEER_TOKEN_FAILURE](state) {
    state.user = null;
    state.scanning_group = null;
  },

  [types.SET_CURRENT_PARTICIPANT](state, participant) {
    state.currentParticipant = participant;
  },

  [types.UNSET_CURRENT_PARTICIPANT](state) {
    state.currentParticipant = null;
  },

  [types.SET_PARTICIPANT_CHECKIN](state, { checkIn }) {
    state.currentParticipant.checked_in = checkIn;
  },

  [types.SET_PARTICIPANT_BIB_NUMBER](state, { value }) {
    state.currentParticipant.bib_number =
      value ?? state.currentParticipant.bib_number;
  },

  [types.SET_CURRENT_ORDER](state, order) {
    state.currentOrder = order;
  },

  [types.SET_SINGLE_ORDER_CHECKIN](state, { checkIn }) {
    state.currentOrder.checked_in = checkIn;
  },

  [types.SET_PARTICIPANT_ORDER_CHECKIN](state, { checkIn, qrCode }) {
    const orders = state.currentParticipant.orders;
    const order = orders.find((o) => o.qr_code === qrCode); // Find the order by QR code
    if (order) {
      order.checked_in = checkIn; // Update the checkIn property of the order
    }
  },
};

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import tenantStore from './tenant/index'

const globalState = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {},
});
globalState.registerModule('tenant', tenantStore);

export default globalState;

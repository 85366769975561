<template>
  <div class="d-flex justify-content-center">
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <toggle-button
      v-else
      color="#60c88a"
      :width="width"
      :height="height"
      :sync="true"
      :value="entity.checked_in ? true : false"
      @change="toggleCheckIn($event)"
    />
  </div>
</template>
<script>
export default {
  props: ["entity", "type", "size"],
  data() {
    return {
      width: 80,
      height: 40,
      loading: false,
    };
  },
  mounted() {
    if (this.size && this.size == "sm") {
      this.width = 40;
      this.height = 20;
    }
  },
  methods: {
    async toggleCheckIn({ value }) {
      this.loading = true;
      await this.$store.dispatch("volunteer/checkInOrCheckOutQrCode", {
        checkIn: value,
        qrCode: this.entity.qr_code,
        type: this.type,
      });
      this.loading = false;
    },
  },
};
</script>
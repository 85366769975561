import * as types from "./mutation-types";

export default {
  [types.TENANT_LOAD_SUCCESS](state, tenant) {
    state.tenant = tenant;
    state.failure = false;
  },

  [types.TENANT_LOAD_FAILURE](state) {
    state.tenant = null;
    state.failure = true;
  },
};

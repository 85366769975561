import introRoutes from "@/modules/Volunteer/views/Intro/intro.routes";
import eventRoutes from "./views/Event/event.routes";
import orderRoutes from "./views/Order/order.routes";

const VolunteerLayout = () => import("./VolunteerLayout.vue");

const moduleRoute = {
  path: "/volunteer",
  component: VolunteerLayout,
  redirect: "/volunteer/intro",
  children: [introRoutes, eventRoutes, orderRoutes],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};

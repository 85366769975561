// import bookingRoutes from "@/modules/Participant/views/Bookings/booking.routes";
// import eventsRoutes from "@/modules/Participant/views/Events/events.routes";
// import homeRoutes from "@/modules/Participant/views/Home/home.routes";
// import profileRoutes from "@/modules/Participant/views/Profile/profile.routes";

//const ParticipantLayout = () => import("./ParticipantLayout.vue");

const moduleRoute = {
  path: "/",
  redirect: "volunteer",
  //   component: ParticipantLayout,
  //   children: [homeRoutes, bookingRoutes, eventsRoutes, profileRoutes],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};

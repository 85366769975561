<template>
  <div>
    <div v-if="loading" class="overlay">
      <div class="text-center text-white w-100">
        <v-row align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center text-white" cols="12">
            Loading...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
    </div>
    <qrcode-stream
      @decode="decoded"
      :track="paintOutline"
      v-on="{ timeout: 3000 }"
    ></qrcode-stream>
  </div>
</template>
<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  props: ["loading"],
  components: {
    QrcodeStream,
  },
  methods: {
    decoded(value) {
      this.$emit("decode", value);
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
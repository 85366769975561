<template>
  <div class="h-100 p-4 d-flex flex-column align-items-between">
    <div class="flex-grow-1">
      <dynamic-data-renderer
        :payload="participant.booking_details"
      ></dynamic-data-renderer>
    </div>
    <check-in :entity="participant" :type="'participant'"></check-in>
  </div>
</template>
<script>
import dynamicDataRenderer from "@/shared/components/dynamic-data-renderer.vue";
import CheckIn from "@/modules/Volunteer/shared/helpers/components/CheckIn.vue";
export default {
  components: { dynamicDataRenderer, CheckIn },
  computed: {
    participant() {
      return this.$store.state.volunteer.currentParticipant;
    },
  },
};
</script>

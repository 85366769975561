import * as types from "./mutation-types";

export default {
  [types.EVENTS_LOAD_SUCCESS](state, events) {
    state.events=events;
  },
  [types.EVENTS_LOAD_FAILURE](state) {
    state.events=[];
  },
  [types.EVENTS_VIEW_SUCCESS](state, event) {
    state.event=event;
  },
  [types.EVENTS_VIEW_FAILURE](state) {
    state.event=null;
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ToggleButton from "vue-js-toggle-button";

// Custom components
Vue.use(ToggleButton);

// modules
import participantModule from "./modules/Participant";
import authModule from "@/modules/Auth";
import volunteerModule from "@/modules/Volunteer";
import { registerModules } from "./register-modules";

// plugins
import vuetify from "./plugins/vuetify";
import api from "./http/api";

//import {messaging} from "./plugins/firebase";
import "./registerServiceWorker";

registerModules({
  auth: authModule,
  participant: participantModule,
  volunteer: volunteerModule,
});

Vue.config.productionTip = false;
Vue.prototype.$http = api;
//Vue.prototype.$messaging = messaging;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import ParticipantContainer from "./components/ParticipantContainer";
import Home from "./components/Home";
import Event from "./Event";
import { VOLUNTEER_KEY } from "@/models/storage";

export default {
  path: "event",
  component: Event,
  beforeEnter: (to, from, next) => {
    const volunteer = JSON.parse(localStorage.getItem(VOLUNTEER_KEY));
    if (volunteer?.user?.api_token != null) {
      next();
    } else {
      next({ name: "volunteer:home" });
    }
  },
  children: [
    {
      path: "",
      name: "event:home",
      component: Home,
    },
    {
      path: "participant/:id",
      name: "event:participant-view",
      component: ParticipantContainer,
    },
  ],
};

import api from "@/http/api";
import { TOKEN_KEY, USER_KEY } from "@/models/storage";
import * as types from "./mutation-types";

export default {
  /**
   * Login action
   */
  async login({ commit }, credentials) {
    const response = await api.post("auth/login", credentials);
    if (response.data.status == "error") {
      commit(types.AUTH_FAILURE);
    } else {
      localStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
      commit(types.AUTH_SUCCESS, response.data);
    }
  },

  /**
   * Check if user is authenticated / stored in local storage
   */
  check({ commit }) {
    const token = localStorage.getItem(TOKEN_KEY);
    const user = JSON.parse(localStorage.getItem(USER_KEY));

    if (token || user) {
      commit(types.AUTH_SUCCESS, { token, user });
    } else {
      commit(types.AUTH_FAILURE);
    }
  },
};

<template>
  <div
    class="text-center bg-primary w-50 align-center p-3 mt-n4 rounded-b-pill"
    :class="{ 'bg-success': participant.checked_in }"
  >
    <div class="fw-bold fs-1" @click="showModal = true">
      {{ bibNumber ?? "---" }}
    </div>

    <v-dialog v-model="showModal" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Bib number </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="bibNumber"
            type="number"
            placeholder="Assign bib number"
            required
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="black darken-1" text @click="showModal = false">
            Cancel
          </v-btn>

          <v-btn color="blue darken-1" text @click="updateBibNumber()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
export default {
  data: () => {
    return {
      showModal: false,
      bibNumber: null,
    };
  },
  mounted() {
    this.bibNumber = this.participant?.bib_number;
  },
  computed: {
    participant() {
      return this.$store.state.volunteer.currentParticipant;
    },
  },
  methods: {
    async updateBibNumber() {
      await this.$store.dispatch("volunteer/updateBibNumber", {
        qrCode: this.participant.qr_code,
        value: this.bibNumber,
      });
      this.showModal = false;
    },
  },
};
</script>
  
import View from "./components/View";
import Order from "./Order";
import { VOLUNTEER_KEY } from "@/models/storage";

export default {
  path: "orders",
  component: Order,
  beforeEnter: (to, from, next) => {
    const volunteer = JSON.parse(localStorage.getItem(VOLUNTEER_KEY));
    if (volunteer?.user?.api_token != null) {
      next();
    } else {
      next({ name: "volunteer:home" });
    }
  },
  children: [
    {
      path: ":id",
      name: "volunteer:order:view",
      component: View,
    },
  ],
};

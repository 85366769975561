export const VOLUNTEER_TOKEN_SUCCESS = "VOLUNTEER_TOKEN_SUCCESS";
export const VOLUNTEER_TOKEN_FAILURE = "VOLUNTEER_TOKEN_FAILURE";

export const SET_CURRENT_PARTICIPANT = "SET_CURRENT_PARTICIPANT";
export const UNSET_CURRENT_PARTICIPANT = "UNSET_CURRENT_PARTICIPANT";

export const SET_PARTICIPANT_CHECKIN = "SET_PARTICIPANT_CHECKIN";
export const SET_PARTICIPANT_BIB_NUMBER = "SET_PARTICIPANT_BIB_NUMBER";
export const SET_PARTICIPANT_ORDER_CHECKIN = "SET_PARTICIPANT_ORDER_CHECKIN";

export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const SET_SINGLE_ORDER_CHECKIN = "SET_SINGLE_ORDER_CHECKIN";

<template>
  <div class="h-100">
    <router-link :to="{ name: 'volunteer:home' }">
      <v-icon class="top-0 position-absolute ms-2 mt-2" color="white" large
        >mdi-keyboard-backspace</v-icon
      >
    </router-link>

    <div
      class="h-100 d-flex align-items-center justify-center px-10 text-center"
    >
      <div v-if="!showQrCodeReader">
        <div class="fs-5 mb-10">
          Hello, To help the organiser of the event could you please enter your
          name?
        </div>
        <v-text-field
          dark
          v-model="name"
          placeholder="Your name please"
          required
        ></v-text-field>
        <v-row class="mt-10">
          <v-btn
            block
            color="blue"
            :disabled="!name"
            @click="showQrCodeReader = true"
          >
            Scan QR code
          </v-btn>
        </v-row>
      </div>
      <div v-if="showQrCodeReader" class="w-100px h-100px">
        <qr-code-reader
          @decode="generateVolunteerForScanningGroup"
          :loading="loading"
        ></qr-code-reader>
        <v-row class="mt-10">
          <v-btn block color="white" @click="showQrCodeReader = false">
            Close
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import QrCodeReader from "@/shared/components/qr-code-reader.vue";

export default {
  components: {
    QrCodeReader,
  },
  data: () => {
    return {
      name: null,
      showQrCodeReader: false,
      loading: false,
    };
  },
  methods: {
    async generateVolunteerForScanningGroup(scanningGroupId) {
      this.loading = true;
      let response = await this.$store.dispatch(
        "volunteer/generateVolunteerForScanningGroup",
        {
          scanningGroupId,
          volunteerName: this.name,
        }
      );
      this.loading = false;
      if (response) {
        this.$router.push({ name: "event:home" });
      } else {
        this.$toast.warning("Failed to authenticate the user");
        this.showQrCodeReader = false;
      }
    },
  },
  computed: {
    eventAuthUrl() {
      return "";
    },
  },
};
</script>

<template>
  <div class="px-5 pt-5 h-100">
    <v-text-field
      dark
      v-model="search"
      placeholder="Free search (First name, Last name, Email..)"
      :debounce="500"
    ></v-text-field>
    <div class="d-flex justify-content-center">
      <v-radio-group v-model="textSearchBy" row @change="saveTextSearchByValue">
        <v-radio label="Participants" value="participants"></v-radio>
        <v-radio label="Orders" value="orders"></v-radio>
      </v-radio-group>
    </div>
    <v-row v-if="loading" align-content="center" justify="center">
      <v-col class="text-subtitle-1 text-center text-white" cols="12">
        Loading results
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <div v-scroll-y class="scrollable-container">
          <v-card
            v-for="(entity, index) in results"
            @click="showEntity(entity.qr_code)"
            :key="index"
            light
            class="mb-3"
          >
            <order-search-item
              :entity="entity"
              v-if="textSearchBy == 'orders'"
            ></order-search-item>
            <participant-search-item
              :entity="entity"
              v-if="textSearchBy == 'participants'"
            ></participant-search-item>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { debounce } from "@/helpers/utils.helpers";
import api from "@/http/api";
import { loadAndGetEntity } from "@/modules/Volunteer/shared/helpers/participant.helper";
import { SEARCH_BY_TEXT_KEY } from "@/models/storage";
import OrderSearchItem from "@/modules/Volunteer/shared/helpers/components/OrderSearchItem.vue";
import ParticipantSearchItem from "@/modules/Volunteer/shared/helpers/components/ParticipantSearchItem.vue";
export default {
  components: {
    OrderSearchItem,
    ParticipantSearchItem,
  },
  data: () => {
    return {
      search: "",
      textSearchBy: "participants",
      results: [],
      loading: false,
      debouncedSearchAPI: null,
    };
  },
  created() {
    this.debouncedSearchAPI = debounce(this.searchAPI, 500);
    const existingSearchByKey = localStorage.getItem(SEARCH_BY_TEXT_KEY);
    if (existingSearchByKey) {
      this.textSearchBy = existingSearchByKey;
    }
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        this.results = [];
      }
      this.debouncedSearchAPI();
    },
  },
  computed: {
    querySearch() {
      return this.search.split(" ").join("+");
    },
    searchUrl() {
      switch (this.textSearchBy) {
        case "orders":
          return `orders?q=${this.querySearch}&limit=15`;
        default:
          return `participants?q=${this.querySearch}&limit=15`;
      }
    },
  },
  methods: {
    async searchAPI() {
      if (this.search.length <= 2) {
        return;
      }
      this.loading = true;
      let { data } = await api.get(this.searchUrl);
      this.results = data;
      this.loading = false;
    },
    saveTextSearchByValue(value) {
      localStorage.setItem(SEARCH_BY_TEXT_KEY, value);
      this.search = "";
    },
    showEntity(entityId) {
      loadAndGetEntity(this.$store, this.$router, entityId);
    },
  },
};
</script>
<style>
.scrollable-container {
  height: 550px;
  overflow-y: auto;
}
</style>
<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.injectTenantData();
    this.$store.dispatch("auth/check");
  },
  methods: {
    async injectTenantData() {
      let tenant = await this.$store.dispatch("loadTenant");
      if (tenant) {
        document.title = tenant.organisation.name;
        this.$vuetify.theme.themes.light.primary =
          tenant.pwa_theme_configuration.primary;
        this.$vuetify.theme.themes.light.accent =
          tenant.pwa_theme_configuration.accent;
        this.$vuetify.theme.themes.light.secondary =
          tenant.pwa_theme_configuration.secondary;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>


<template>
  <div class="h-100">
    <sidebar></sidebar>
    <v-card class="h-100 d-flex flex-column" dark>
      <div class="d-flex w-100 test justify-content-center">
        <v-btn
          class="mt-4 p-4 shadow-lg mx-2"
          elevated
          background-color="red"
          v-for="(tab, index) in tabs"
          :key="tab.key"
          @click="setCurrentTab(index)"
        >
          <v-icon v-if="tab.icon" class="text-white" color="white">
            {{ tab.icon }}
          </v-icon>
          {{ tab.label }}
        </v-btn>
      </div>
      <div class="flex-grow-1">
        <v-tabs-items v-model="currenTab" dark class="w-100 h-100">
          <v-tab-item v-for="tab in tabs" :key="tab.key" class="h-100">
            <qr-search v-if="tab.key === 'qr' && currenTab === 0"> </qr-search>
            <text-search
              v-if="tab.key === 'text' && currenTab == 1"
            ></text-search>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>
<script>
import Sidebar from "@/modules/Volunteer/shared/helpers/components/Sidebar.vue";
import QrSearch from "./Search/QrSearch.vue";
import TextSearch from "./Search/TextSearch.vue";

export default {
  components: {
    QrSearch,
    TextSearch,
    Sidebar,
  },
  data: () => {
    return {
      currenTab: 0,
      tabs: [
        {
          key: "qr",
          icon: "mdi-qrcode-scan",
        },
        {
          key: "text",
          icon: "mdi-keyboard",
        },
      ],
    };
  },
  methods: {
    setCurrentTab(index) {
      this.currenTab = index;
    },
  },
};
</script>
<style scoped lang="scss">
button {
  padding: 2.2em !important;
}
</style>

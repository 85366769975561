import * as types from "./mutation-types";
import api from "@/http/api";

export default {
  /**
   * Load bookings action
   */
  async loadBookings({ commit }, userId) {
    const response = await api.get(`users/${userId}/participants`);
    if (response.data.status == "error") {
      commit(types.BOOKINGS_LOAD_FAILURE);
    } else {
      commit(types.BOOKINGS_LOAD_SUCCESS, response.data);
    }
  },
};

import * as types from "./mutation-types";
import { VOLUNTEER_KEY, TENANT_KEY, TOKEN_KEY } from "@/models/storage";
import api from "@/http/api";

const volunteer = JSON.parse(localStorage.getItem(VOLUNTEER_KEY));
let apiToken = volunteer?.user?.api_token;
api.defaults.headers.token = apiToken;

export default {
  async generateVolunteerForScanningGroup({ commit }, payload) {
    const tenant = JSON.parse(localStorage.getItem(TENANT_KEY));
    let apiToken = tenant?.api_key;
    api.defaults.headers.token = apiToken;
    const response = await api.post(
      `scanning_groups/${payload.scanningGroupId}/users/volunteer`,
      {
        name: payload.volunteerName,
      }
    );
    if (response.status == "error") {
      commit(types.VOLUNTEER_TOKEN_FAILURE);
      return false;
    } else {
      localStorage.setItem(VOLUNTEER_KEY, JSON.stringify(response.data));
      localStorage.setItem(TOKEN_KEY, response.data?.user?.api_token);
      commit(types.VOLUNTEER_TOKEN_SUCCESS, response.data);
      return true;
    }
  },

  async getQrEntity({ commit }, { entityId }) {
    const response = await api.get(`qr_codes/${entityId}`);
    let result = {
      success: true,
    };
    if (response.status == "error") {
      return {
        success: false,
      };
    }
    const entity = response.data;

    if (entity.type == "participant") {
      commit(types.SET_CURRENT_PARTICIPANT, entity);
      result.redirect = "event:participant-view";
    } else {
      commit(types.SET_CURRENT_ORDER, entity);
      result.redirect = "volunteer:order:view";
    }
    return result;
  },

  /**
   * Returns false if check-in/out fails
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async checkInOrCheckOutQrCode({ commit }, { checkIn, type, qrCode }) {
    let { status, data } = await api.put(`qr_codes/${qrCode}`, {
      action: checkIn ? "check-in" : "check-out",
    });

    if (status != "success") {
      checkIn = null;
    } else {
      checkIn = data["checked-in"];
    }
    switch (type) {
      case "participant":
        commit(types.SET_PARTICIPANT_CHECKIN, { checkIn });
        break;
      case "order":
        commit(types.SET_PARTICIPANT_ORDER_CHECKIN, { checkIn, qrCode });
        break;
      case "single-order":
        commit(types.SET_SINGLE_ORDER_CHECKIN, { checkIn });
        break;
      default:
        console.info("Invalid type");
    }
    return checkIn != null;
  },

  async updateBibNumber({ commit }, { qrCode, value }) {
    let { status, data } = await api.put(`qr_codes/${qrCode}`, {
      action: "bib-number",
      value,
    });
    if (status != "success") {
      value = null;
    } else {
      value = data["bib-number"];
    }
    commit(types.SET_PARTICIPANT_BIB_NUMBER, { value });
    return value != null;
  },

  check({ commit }) {
    const volunteer = JSON.parse(localStorage.getItem(VOLUNTEER_KEY));
    if (volunteer) {
      commit(types.VOLUNTEER_TOKEN_SUCCESS, volunteer);
    } else {
      commit(types.VOLUNTEER_TOKEN_FAILURE);
    }
  },
};

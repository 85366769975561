<template>
  <div
    class="w-100 text-center bg-primary py-3"
    :class="{ 'bg-success': participant.checked_in }"
  >
    <div
      v-if="participant.checked_in"
      class="position-absolute left-0 fw-bold check-in-label end-0 me-5"
    >
      <v-icon color="white" class="fs-xxl"> mdi-check-circle </v-icon>
    </div>
    <div class="fs-1 fw-bold">
      {{ participant.last_name }}
    </div>
    <div class="fs-5">
      {{ participant.first_name }}
    </div>
    <div class="mt-5">{{ gender }} {{ age }}</div>
    <div class="mt-4">
      <div class="fw-bold">{{ participant.occasion }}</div>
      <div class="mt-2">
        {{ participant.category }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    participant() {
      return this.$store.state.volunteer.currentParticipant;
    },
    age() {
      if (this.gender) {
        return this.participant.age ? `(${this.participant.age})` : "";
      }
      return this.participant.age ? `Age: ${this.participant.age}` : "";
    },
    gender() {
      switch (this.participant.gender) {
        case "m":
          return "Male";
        case "f":
          return "Female";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.fs-xxl {
  font-size: 2.5em;
}
</style>

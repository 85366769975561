import GetStarted from "./components/GetStarted";
import Home from "./components/Home";
import Intro from "@/modules/Volunteer/views/Intro/Intro";

export default {
  path: "intro",
  component: Intro,
  children: [
    {
      path: "",
      name: "volunteer:home",
      component: Home,
    },
    {
      path: "get-started",
      name: "volunteer:get-started",
      component: GetStarted,
    },
  ],
};

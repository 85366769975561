<template>
  <div class="d-flex flex-column justify-content-center align-center h-100">
    <v-btn
      variant="text"
      class="position-absolute top-0 start-0 m-2 mt-10"
      to=".."
      :icon="true"
    >
      <v-icon size="x-large" color="white">mdi-keyboard-backspace</v-icon>
    </v-btn>
    <Info></Info>
    <AssignBib></AssignBib>
    <v-tabs
      v-model="currentTab"
      class="mt-5"
      fixed-tabs
      background-color="#292929"
      dark
    >
      <v-tab v-for="tab in tabs" :key="tab.key">
        <v-icon class="text-white" color="white"> {{ tab.icon }} </v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab" dark class="w-100 h-100">
      <v-tab-item v-for="tab in tabs" :key="tab.key" class="h-100">
        <Entry v-if="tab.key === 'entry'" />
        <ParticipantView v-if="tab.key === 'view'" />
        <Order v-if="tab.key === 'order'" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Info from "./Participant/Info.vue";
import AssignBib from "./Participant/AssignBib.vue";
import Entry from "./Participant/Entry/Entry.vue";
import Order from "./Participant/Order/Order.vue";
import ParticipantView from "./Participant/ParticipantView/ParticipantView.vue";

export default {
  components: {
    Info,
    AssignBib,
    Entry,
    Order,
    ParticipantView,
  },
  data() {
    return {
      currentTab: null,
      tabs: [
        {
          key: "entry",
          icon: "mdi-form-select",
        },
        {
          key: "view",
          icon: "mdi-account-multiple",
        },
        {
          key: "order",
          icon: "mdi-package-check",
        },
      ],
    };
  },
};
</script>
